// 引入封装的axios请求文件
import req from '../utils/request.js'

export function messageList (params) {
  return req.post('messageList', params)
}

export function messageUnread (params) {
  return req.post('messageUnread', params)
}

export function messageDelete (params) {
  return req.post('messageDelete', params)
}

export function messageMarkAsRead (params) {
  return req.post('messageMarkAsRead', params)
}

export function messageDetail (params) {
  return req.post('messageDetail', params)
}
